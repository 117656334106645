<template>
  <main>
    <make-banner height="320"></make-banner>
    <div class="core">
      <make-breadcrumb></make-breadcrumb>
      <!-- <div class="seckill white row">
        <img src="@/assets/icon/icon-light.png" alt="" class="mr10" />
        <span class="xl mr10">慧采秒杀</span>
        <span class="mr10 row">距结束:</span>
        <make-countdown class="time-box row lg bold" :startTime="seckillTime.end_time_int">
        </make-countdown>
      </div> -->
      <make-storelist
        activityType="2"
        priceSize="20"
        height="320"
        :join="false"
        :goodsList="lists"
      ></make-storelist>
      <make-pagination
        @resetList="resetList"
        :orderObject="orderObject"
        v-if="lists.length"
      ></make-pagination>
    </div>
  </main>
</template>

<script>
import MakeStroeList from "@/components/MakeStoreList/index.vue";
import MakeCountDown from "@/components/MakeCountDown/index.vue";
export default {
  name: "Discount",
  components: {
    "make-storelist": MakeStroeList,
    "make-countdown": MakeCountDown,
  },
  data() {
    return {
      seckillTime: {},
      lists: [],
      page_no: 1,
      page_size: 10,
      orderObject: {},
    };
  },
  methods: {
    getSeckillTime() {
      this.$api
        .getSeckillTimeApi({
          page_no: 1,
          page_size: 9999,
        })
        .then((res) => {
          if (res.code == 1) {
            let arr = res.data.filter((el) => el.tips == "抢购中");
            if (arr.length) {
              this.seckillTime = arr[0];
              this.getLists();
            }
          }
        });
    },
    getLists() {
      this.$api
        .getSeckillListApi({
          seckill_id: this.seckillTime.id,
          page_size: 20,

          page_no: this.page_no,
        })
        .then((res) => {
          this.lists = res.data.lists.map((el) => {
            el.image = el.goods_image;
            el.id = el.goods_id;
            el.name = el.goods_name;
            el.min_price = el.seckill_price;
            el.market_price = el.goods_min_price;
            return el;
          });
          let { list, count, page_size: size, page_no: page } = res.data;
          this.orderObject = res.data;
          this.orderObject = {
            page,
            size,
            count,
            list,
          };
        });
    },
    resetList(e) {
      this.page_no = e;
      this.getLists();
    },
  },
  created() {
    this.getSeckillTime();
  },
};
</script>
<style lang="scss" scoped>
.seckill {
  background: linear-gradient(#f28101, #f08001);
  padding: 15px;
  margin-bottom: 21px;

  img {
    width: 15px;
    height: 31px;
  }

  .time-box {
    span {
      display: inline-block;
    }
  }
}
</style>
